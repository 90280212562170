import React, { useEffect, useRef } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useInventoryContext } from '../contexts/InventoryContext';
import { useGlobalContext } from '../contexts/GlobalContext';
import logo from '../assets/images/main-logo.svg';
import Loading from '../components/loading/Loading';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const { api_url, isLoading, isAuthenticated, showNavbarOffCanvas, setShowNavbarOffCanvas, setIsAuthenticated, getCategories, categoryData, getCartItems, cartLength, wishlistData, WishlistLength,
        getAllWishlistItems, userInformation, img_url } = useGlobalContext();
    const { getPageInventory, searchKeyword, setSearchKeyword, getLastCategories, setLastCategories } = useInventoryContext();
    // useEffect(() => {
    //     setLastCategories([])


    // }, [pathname])

    // const [showNavbarOffCanvas, setShowNavbarOffCanvas] = useState(false)

    // const handleOffCanvasClose = () => setShowOffCanvas(false);
    // const handleOffCanvasShow = () => setShowOffCanvas(true);

    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Get All Categories
    let userId=null, sessionType;

    const url = `${api_url}/pageInventory`;
    if (sessionStorage.getItem("user_id")) {
        userId = sessionStorage.getItem("user_id");
        sessionType = "user";
    } else {
        userId = sessionStorage.getItem("guest_id");
        sessionType = "guest";
    }
    const hasRunRef = useRef(false);
    useEffect(() => {
        if (userId!==null && !hasRunRef.current) {
            hasRunRef.current = true;
            getCategories();
            getCartItems();
            getAllWishlistItems()
        }
    }, [userId,hasRunRef]);


    useEffect(() => {
        setSearchKeyword('')
    }, [location]);

    const username = sessionStorage.getItem('username');

    // const url = `${api_url}/pageInventory`;

    const handleSearch = () => {
        // getPageInventory(url);
        if (location.pathname !== '/marketplace') {
            // navigate('/marketplace')
            navigate({
                pathname: '/marketplace',
                search: `?search=${searchKeyword}`,
            });
        }
        else {
            navigate({
                pathname: '/marketplace',
                search: `?search=${searchKeyword}`,
            });
            window.location.reload()
        }
        // navigate('/marketplace')
        // navigate('/marketplace', { state: {'searchKeyword' : searchKeyword} });
    }

    const handleCategory = (cat_id, subcat_id, subcat2_id) => {
        getPageInventory(url, null, cat_id, subcat_id, subcat2_id);
        getLastCategories(cat_id, subcat_id, subcat2_id)
        navigate('/marketplace', { state: { cat_id: cat_id, subcat_id: subcat_id, subcat2_id: subcat2_id } })
    }

    // when sign out
    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user_id');
        sessionStorage.removeItem('school_code');
        sessionStorage.removeItem('username')
        if (window.Android && window.Android.logout) {
            window.Android.logout();
        }
        else
            navigate('/');
        window.location.reload();
    }
    return (
        <>
            {isLoading ? <Loading /> : null}
            <header>
                <nav className="navbar shadow-4 w-100 top-0 navbar-expand-md navbar fixed-top navbar-light bg-light " style={{ zIndex: "25" }} >
                    <div className="container">
                        <Link to={'/'} className="navbar-brand order-md-1 d-none d-md-block" ><img className='w-100' src={logo} alt="e-vyapari-logo" /></Link>
                        {/* {JSON.stringify(location)} */}

                        <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#mynavbar">
                            <span className="navbar-toggler-icon text-dark" />
                        </button>
                        <div className='order-2'>
                            {
                                isAuthenticated ?
                                    <div className='h-100 d-block d-md-none dropdown'>
                                        <Link to={'/myprofile'} className="btn btn-sm d-block d-md-none bg-transparent border-2 border-danger rounded-3 text-center" id="navbarDarkDropdownMenuLink2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div>
                                                <i className="ti ti-user-filled text-danger fs-6" />

                                            </div>

                                        </Link>
                                        <ul className="dropdown-menu p-3 profile " aria-labelledby="navbarDarkDropdownMenuLink2">
                                            <li>
                                                <Link to={'/myprofile'} className='text-danger fs-5 text-decoration-none'>
                                                    <i className="ti ti-user" /> My Profile
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/wishlist'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className="text-secondary fs-5 text-decoration-none">
                                                    <i className="ti ti-heart" />
                                                    WishList
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={() => handleLogout()} className='text-secondary fs-5 text-decoration-none'>
                                                    <i className="ti ti-logout-2" /> Logout
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    <div className='h-100 d-block d-md-none dropdown'>
                                        <Link to={'/myprofile'} className="btn btn-sm d-block d-md-none bg-transparent border-2 border-danger rounded-3 text-center" id="navbarDarkDropdownMenuLink2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div>
                                                <i className="ti ti-user-filled text-danger fs-6" />

                                            </div>

                                        </Link>
                                        <ul className="dropdown-menu p-3 profile " aria-labelledby="navbarDarkDropdownMenuLink2">
                                            <li>
                                                <Link to={'/login'} className="text-secondary fs-5 text-decoration-none">
                                                    <div>
                                                        <i className="ti ti-login-2 fs-5" /> Login
                                                    </div>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/wishlist'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className="text-secondary fs-5 text-decoration-none">
                                                    <i className="ti ti-heart" />
                                                    WishList
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={() => handleLogout()} className='text-secondary fs-5 text-decoration-none'>

                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                            }

                        </div>
                        <div className="offcanvas offcanvas-start order-md-2" id="mynavbar">
                            <div className="offcanvas-header">
                                <div className="d-flex mt-4">

                                    {/* <img src={require("../assets/images/user.png")} alt="user-profile" /> */}
                                    <img src={userInformation?.profile ? `${img_url}/${userInformation?.folder}/${userInformation?.profile}` : require("../assets/images/user.png")} className='object-fit-fill rounded-3 ' style={{ width: '40px', height: '40px' }} alt="user-profile" />


                                    <h4 className='mb-0 ms-1 pt-1'> Hii, {username ? username : 'Guest user'}</h4>

                                </div>

                                <div className="offcanvas-title d-none d-md-block"><img src={logo} alt="e-vyapari-logo" /></div>

                                <button type="button" className="btn-close text-reset d-none" data-bs-dismiss="offcanvas" />
                            </div>

                            <div className="offcanvas-body h-100   ">

                                <ul className={`navbar-nav mx-auto align-items-md-center ${pathname === '/marketplace' || pathname === '/vendorshop' ? 'd-none' : ''} `} >
                                    {
                                        categoryData.length !== 0 &&
                                        categoryData.map((ctg, i) => {
                                            const { cat_name, cat_id, subcat } = ctg;
                                            return (
                                                <li className="nav-item title  dropdown-center" key={cat_id}>

                                                    <a className="nav-link " href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {cat_name}

                                                    </a>
                                                    <ul className="dropdown-menu  py-3 navbar-drop p-md-3  " aria-labelledby="navbarDarkDropdownMenuLink">

                                                        <div className="d-flex gap-4 flex-column flex-md-row">
                                                            {
                                                                subcat.map((subcat, i) => {
                                                                    const { subcat_name, subcat_id, subcat2 } = subcat;
                                                                    return (
                                                                        <div className="" key={subcat_id}>
                                                                            <h5 className="text-danger ms-2 text-nowrap">{subcat_name}</h5>
                                                                            <ul className='ps-1'>
                                                                                {
                                                                                    subcat2.map((subcat2, i) => {
                                                                                        const { subcat2_name, subcat2_id } = subcat2;
                                                                                        return (
                                                                                            <li className='' key={i}>
                                                                                                <div className=' pb-1'>
                                                                                                    <button className='btn bg-transparent dropdown-item listicat cursor-pointer' onClick={() => handleCategory(cat_id, subcat_id, subcat2_id)}>{subcat2_name}</button>
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {/* <li><a className="dropdown-item" href="#">Junior</a></li>
                                                                                <li><a className="dropdown-item" href="#">School Bags</a></li>
                                                                                <li><a className="dropdown-item" href="#">Laptop Bags</a></li>
                                                                                <li><a className="dropdown-item" href="#">Travel Bags</a></li> */}
                                                                            </ul>
                                                                        </div>)
                                                                })}

                                                        </div>
                                                    </ul>
                                                </li>)

                                        })}
                                </ul>

                                <div className={`d-flex gap-2 order-md-3  ${pathname === '/marketplace' || pathname === '/vendorshop' ? 'justify-content-end w-100' : ''} `} >
                                    <div className={`navbar-nav w-50  align-items-md-center justify-content-end ${pathname === '/marketplace' || pathname === '/vendorshop' ? 'd-md-flex d-none' : 'd-none'} `}>
                                        <div className="input-group shadow-md rounded-3 " >
                                            <input type="text" className="form-control  border-end-0" onChange={(e) => setSearchKeyword(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") handleSearch() }} value={searchKeyword !== null ? searchKeyword : ''} placeholder="Search Items" />
                                            <div className="input-group-append">
                                                <span className="input-group-text search-icon  h-100 cursor-pointer" onClick={() => handleSearch()}>
                                                    <i className="fa-solid fa-magnifying-glass p-2 text-danger  text-light rounded-3" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to={'/cart'} className="btn  d-none d-md-block btn-white border border-dark cart-btn py-1 ">
                                        <div className='d-flex flex-column position-relative h-100'>
                                            <div className={cartLength > 0 ? 'cart-animation position-absolute top-0 end-0 bg-danger rounded-circle' : 'd-none'}>
                                                <div className={cartLength > 0 ? 'cart-icon' : ''} data-totalitems={cartLength}>
                                                    {cartLength}

                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-center align-items-center h-100'>

                                                <i className="ti ti-shopping-cart" /> Cart
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={'/wishlist'} className="btn  d-none d-md-block btn-white border border-dark cart-btn py-1">
                                        <div className='d-flex flex-column position-relative h-100'>
                                            <div className={WishlistLength > 0 ? 'cart-animation position-absolute top-0 end-0 bg-danger rounded-circle' : 'd-none'}>
                                                <div className={WishlistLength > 0 ? 'cart-icon' : ''} data-totalitems={WishlistLength}>
                                                    {WishlistLength}
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                                                <i className="ti ti-heart" />
                                                Wish
                                            </div>

                                        </div>
                                    </Link>
                                    <NavLink to="/marketplace" onClick={() => setShowNavbarOffCanvas(false)} id='marketplace_link' className="btn btn-outline-dark marketplace text-dark d-none  btn-ani d-md-flex align-items-center justify-content-center text-center">
                                        <span>Marketplace <i className="fa-solid fa-caret-right" /></span>
                                    </NavLink>
                                    {
                                        isAuthenticated ?
                                            <div className='h-100 d-none d-md-block dropdown'><Link to={'/myprofile'} className="btn userprofilebtn position-relative border border-danger h-100 btn-danger flex-column rounded-3 d-flex align-items-center" id="navbarDarkDropdownMenuLink2" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                                                <div>
                                                    <i className="ti ti-user" />

                                                </div>
                                                <div>Profile </div>
                                            </Link>
                                                <ul className="dropdown-menu profile p-3" aria-labelledby="navbarDarkDropdownMenuLink2">
                                                    <li>
                                                        <Link to={'/myprofile'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-danger fs-5 text-decoration-none'>
                                                            <i className="ti ti-user" /> My Profile
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => handleLogout()} className='text-secondary fs-5 text-decoration-none'>
                                                            <i className="ti ti-logout-2" /> Logout
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            <Link to={'/login'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className="btn  d-none d-md-block btn-danger text-white border border-dark cart-btn py-1">
                                                <div className='d-flex flex-column position-relative h-100'>

                                                    <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                                                        <i className="fa-solid fa-right-to-bracket" />
                                                        Login
                                                    </div>

                                                </div>
                                            </Link>
                                        // <Link to={'/login'} className="btn  d-none d-md-block btn-white border border-dark cart-btn py-1">
                                        //     <div>
                                        //         <i className="fa-solid fa-right-to-bracket" />
                                        //     </div>
                                        //     <div>Login </div>
                                        // </Link>
                                    }


                                </div>
                            </div>
                            <div className="offcanvas-footer d-md-none d-block mb-4 mx-3">
                                <div className='mb-2 d-flex gap-1 justify-content-between'>

                                    <Link to={'/popularschool'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className="btn border border-2 border-dark border-bottom-3 d-block d-md-none  gap-2 d-flex rounded-3 justify-content-center align-items-center w-100">

                                        {/* <i className="fa-solid fa-right-to-bracket" /> */}

                                        <div> Popular Schools </div>
                                    </Link>
                                    {/* <Link to={'/wishlist'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className="btn border border-2 border-dark border-bottom-3 d-block d-md-none  gap-2 d-flex rounded-3 justify-content-center align-items-center">
                                        <i className="ti ti-heart" />



                                        <div> Wish </div>
                                    </Link> */}
                                </div>
                                <div>
                                    {
                                        isAuthenticated ?

                                            <Link onClick={() => handleLogout()} className='btn border border-2 border-dark d-block d-md-none  gap-2 d-flex rounded-3 justify-content-center  border-bottom-3 align-items-center'>
                                                <i className="ti ti-logout-2" /> Logout
                                            </Link>
                                            :
                                            <Link to={'/login'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className="btn border border-2 border-dark border-bottom-3 d-block d-md-none  gap-2 d-flex rounded-3 justify-content-center align-items-center">

                                                <i className="fa-solid fa-right-to-bracket" />

                                                <div> Login </div>
                                            </Link>
                                    }
                                </div>

                            </div>
                        </div>
                    </div></nav>
            </header>
        </>
    )
}

export default Header