import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { InfoValidation } from './FormValidation';
import { ToastContainer, toast } from 'react-toastify';

import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import logo from '../assets/images/main-logo.svg';

import Header from '../layout/Header';
import Loading from '../components/loading/Loading';
import ProfileSidebar from '../components/ProfileSidebar';

const MyProfileInformation = () => {

  const { userId, isLoading, location, userInformation, setIsLoading, navigate, setIsAuthenticated, onlyNumbers, disabled, handleBtn, setDisabled, api_url, districts, stateData, handleStateChange } = useGlobalContext();
  // const {isAuthenticated} = useGlobalContext();
  const [infoErrors, setInfoErrors] = useState({});
  const [informationData, setInformationData] = useState({
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone_no": "",
    // "dob": "",
    "state": "",
    "district": "",
    "post_office": "",
    "address": "",
    "village": "",
    "city": "",
    "pincode": "",
    "school_code": '',
    "classno": ""
  });

  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // january is 0
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  let todayDate = yyyy + "-" + mm + "-" + dd;

  //get user information
  useEffect(() => {
    setIsLoading(true);
    axios.get(`${api_url}/getInformation/${sessionStorage.getItem('user_id')}`)
      .then(res => {
        if (res.data.success === 1) {
          let infoData = res.data?.data;
          setIsLoading(false);
          setDisabled(true);
          if (location.state?.schoolcode) {
            setInformationData({ ...infoData, school_code: location.state.schoolcode });
          }
          else {
            setInformationData(res.data?.data);
          }
          // console.log(infoData)
          handleStateChange(null, res.data.data.state);
        }
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }, [])

  //Update information
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = InfoValidation(informationData);
    setInfoErrors(errors);
    console.log(errors)

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      axios.post(`${api_url}/updateInformation/${sessionStorage.getItem('user_id')}`, informationData)
        .then(res => {
          if (res.data.success === 1) {
            setIsLoading(false);
            setDisabled(true)
            sessionStorage.removeItem('school_code')
            res.data.data.school_code !== null && sessionStorage.setItem('school_code', res.data.data.school_code);
            toast.success(res.data.message, {
              autoClose: 1500,
              theme: 'colored'
            });
            const timer = setTimeout(() => {
              navigate(-1);
            }, 2000)
            return () => clearTimeout(timer);
          } else {
            toast.error(res.data.message, {
              autoClose: 2000,
              theme: 'colored'
            });
            setIsLoading(false);
          }
        })
        .catch(err => {
          toast.error('Axios Error', {
            autoClose: 2000,
          });
          console.log(err)
          setIsLoading(false);
        })
    }
  };


  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [])


  const handleChange = (e) => {
    setInformationData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleState = (e) => {
    handleStateChange(e);
    setInformationData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }


  return (
    <>
      {isLoading ? <Loading /> : null}
      {console.log(userInformation)}
      <Header />
      <main id='main' className='mt-5 pt-5 space-top'>
        <div className="eVyapari-content w-100">
          <div className="text-center  mb-4 d-block d-md-none">
            <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
          </div>
        </div>
        <section id="my-personal-info" className="pb-5 clearfix">
          <div className="container">
            <ProfileSidebar />
            {/* {JSON.stringify(informationData)} */}
            {/*--------------My Personal Information-form---------*/}
            <div className="float-md-end bill-details w-75">
              <div className=" bg-white border border-1 p-4 rounded-4 border border-dark shadow-btm">
                <div className="d-flex justify-content-between bg-white">
                  <div><h6 className="font-cart fw-bold">My Personal Information</h6></div>
                </div>
                <form className="bg-white">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="fname" className="form-label text-secondary">First Name<span className="text-danger">*</span></label>
                        <input type="text" onChange={handleChange} name='first_name' value={informationData.first_name} className="form-control" id="fname" placeholder="First Name" />
                        <div className="text-danger">{infoErrors.first_name}</div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="lname" className="form-label text-secondary">Last Name<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" onChange={handleChange} name='last_name' value={informationData.last_name} id="lname" placeholder="Last Name" />
                        <div className="text-danger">{infoErrors.last_name}</div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="number" className="form-label text-secondary">Mobile Number<span className="text-danger">*</span></label>
                        <input type="tel" onKeyDown={(e) => onlyNumbers(e)} onChange={handleChange} maxLength={10} name='phone_no' value={informationData.phone_no} className="form-control" id="number" placeholder="Mobile Number" />
                        <div className="text-danger">{infoErrors.phone_no}</div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3 ">
                        <label htmlFor="email" className="form-label text-secondary">Email Address</label>
                        <input type="email" className="form-control" id="email" onChange={handleChange} name='email' value={informationData.email} placeholder="Email" />
                      </div>
                      <div className="text-danger">{infoErrors.email}</div>
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="district" className="form-label text-secondary">Select
                        State<span className="text-danger">*</span></label>
                      <select className="form-select " onChange={(e) => handleState(e)} name='state' value={informationData.state} aria-label="Default select example">
                        <option value="Select State">--Select State--</option>
                        {
                          stateData.map((state, i) => {
                            return (
                              <option key={i} value={state.state_name}>{state.state_name}</option>
                            )
                          })
                        }
                      </select>
                      <div className="text-danger">{infoErrors.state}</div>
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="district" className="form-label text-secondary">Select
                        District<span className="text-danger">*</span></label>
                      <select className="form-select" onChange={handleChange} name='district' value={informationData.district} aria-label="Default select example">
                        <option>--Select District--</option>
                        {
                          districts.map((district, i) => {
                            return (
                              <option key={i} value={district}>{district}</option>
                            )
                          })
                        }
                      </select>
                      <div className="text-danger">{infoErrors.district}</div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="postoffice" className="form-label text-secondary">Post Office<span className="text-danger">*</span></label>
                        <input type="postoffice" className="form-control" id="postoffice" onChange={handleChange} name='post_office' value={informationData.post_office} placeholder="Post Office" /> <div className="text-danger">{infoErrors.post_office}</div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="landmark" className="form-label text-secondary">Land Mark/House no.</label>
                        <input type="text" className="form-control" id="landmark" onChange={handleChange} name='address' value={informationData.address} placeholder="Landmark" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="ward" className="form-label text-secondary">Village/Ward No.<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" onChange={handleChange} name='village' value={informationData.village} id="ward" placeholder="Village/Ward No./Sec." /> <div className="text-danger">{infoErrors.village}</div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="city" className="form-label text-secondary ">City<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" onChange={handleChange} name='city' value={informationData.city} id="city" placeholder="City" />
                        <div className="text-danger">{infoErrors.city}</div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="pin-code" className="form-label text-secondary">Pin code<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" onChange={handleChange} name='pincode' value={informationData.pincode} id="pin-code" placeholder="Pincode" /> <div className="text-danger">{infoErrors.pincode}</div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label htmlFor="schl-code" className="form-label text-secondary">School code</label>
                        <input type="text" className="form-control" id="schl-code" disabled={userInformation?.userfrom === 1} onChange={handleChange} name='school_code' value={informationData.school_code} placeholder="School code" />
                      </div>
                    </div>
                  </div>
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <button type="button" className="btn btn-white border border-1 text-black w-100" onClick={() => navigate(-1)}>Cancel</button>
                    </div>
                    <div className="col-lg-6">
                      <button className="btn bg-danger border border-dark text-light shadow-btm w-100" type="button" onClick={(e) => handleSubmit(e)}>Update</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>


      </main>

      {/* <Copyright /> */}

      <ToastContainer />
    </>
  )
}

export default MyProfileInformation