import React, { memo, useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link, useSearchParams } from 'react-router-dom'
import Header from '../layout/Header'
import Loading from '../components/loading/Loading'
import logo from '../assets/images/main-logo.svg';
import imgPreview from '../assets/images/no-preview-available.png';
const TableRow = memo(({ item, index }) => (
    <tr key={index}>
        <th scope="row">{index + 1}</th>
        <td><img src={`${item.img}`} alt="book" width={60} /></td>
        <td>{item.itemname}</td>
        <td>{item.classno}</td>
        <td>
            {parseFloat(item.set_price) === parseFloat(item.discounted_price) ? '' : <del>₹ {item.set_price}</del>}
            &nbsp;&nbsp;₹{item.discounted_price}
        </td>
        <td>{item.company_name}</td>
        <td>{item.qty}</td>
    </tr>
));
const MySchool = () => {
    const { userId, schoolCode, navigate, img_url, getSchoolInformation, schoolData, isLoading, sessionType, setCartLength, api_url, schoolSetData, setIsLoading, getSchoolSet, userInformation, getUserInformation, setIsAuthenticated } = useGlobalContext();
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [SchoolCode, setSchoolCode] = useState('');
    const [cats, setCats] = useState([]);
    const [tokendata, setTokenData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const hasRunRef = useRef(false);
    // const { folder, image, link } = inventoryDetails;
    // useEffect(() => {
    //     const token = searchParams.get('tokenlogin');
    //     if (token) {
    //         getTokenValidation(token)
    //     }
    // }, [searchParams]);
    const getTokenValidation = async (token) => {
        const data = {
            'token': token
        };
        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/tokenvalidation`, data);
            if (res.data.success === 1) {
                setTokenData(res.data.data)
            }
            else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: 'colored'
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if (!hasRunRef.current) {
            hasRunRef.current = true;
            getUserInformation()
            getSchoolSet();
            getSchoolInformation();
            getSetCategories();
        }
    }, []);
    const [currentPage, setCurrentPage] = useState(0);
    //getSetCategories
    const getSetCategories = async () => {
        const data = {
            'school_code': schoolCode
        };
        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/getSetCategories`, data);
            if (res.data.success === 1) {
                setCats(res.data.data)
            }
            else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: 'colored'
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    // console.log("Categories", cats)

    //addSetToCart
    const addSetToCart = async (set_id, vendor_id, set_qty, redirect = false) => {
        const data = {
            'user_id': userId,
            'session_type': sessionType,
            'set_id': set_id,
            'vendor_id': vendor_id
        };

        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/addSetToCart`, data);
            if (res.data.success === 1) {
                toast.success(res.data.message, {
                    autoClose: 1500,
                    theme: 'colored'
                })
                setCartLength((prev) => prev + set_qty);
                setShowPreviewModal(false);
            }
            else if (res.data.success === 0 && redirect === false) {
                toast.error(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored'
                })
                setShowPreviewModal(false);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
            if (redirect) {
                navigate('/cart')
            }
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            {console.log(tokendata)}
            {console.log(userInformation)}
            <Header />
            <main id='main' className='mt-5 pt-5 space-top'>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-4 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>
                {/* {JSON.stringify(schoolData)} */}
                <section id="myschool">
                    <div className="container">
                        <div className=" shadow-btm rounded-4 mt-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card border border-dark flex-row h-100 rounded-4">
                                        <div className="overflow-hidden schoolimg  rounded-4 h-100 w-100 ">
                                            {
                                                schoolData.school_banner !== null ?
                                                    <img src={`${img_url}/${schoolData.folder}/${schoolData.school_banner}`} alt="school banner " className="card-img-top w-100 h-100 object-fit-contain" />
                                                    : <img src={require(`../assets/images/school_banner_2.jpeg`)} className="card-img-top w-100 h-100 object-fit-contain" alt='.' />
                                            }

                                        </div>
                                        <div className="card-body h-md-100">
                                            <h4 className="pt-sm-5 pt-0">{schoolData.school_name}</h4>
                                            <h5>{schoolData.village && schoolData.village}{schoolData.landmark && `, ${schoolData.landmark}`} <br />{schoolData.zipcode && `Pincode: ${schoolData.zipcode}`} {schoolData.post_office && `, Post Office: ${schoolData.post_office}`} {schoolData.city && schoolData.city}{schoolData.distt && `, ${schoolData.distt}`}
                                                {schoolData.state && `, ${schoolData.state}`}</h5>
                                            {userInformation?.userfrom === 0 && <div className="input-group mt-3 w-100 border border-dark rounded-3 d-none d-md-flex">
                                                <input type="text" className="form-control border-0" placeholder="Search Code" onChange={(e) => setSchoolCode(e.target.value)} />
                                                <button className="btn btn-danger m-1 rounded-0 me-2" type="submit" onClick={() => navigate('/myinformation', { state: { schoolcode: SchoolCode } })}>Change
                                                    School</button>
                                            </div>}

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        {userInformation?.userfrom === 0 &&
                            <div className="input-group mt-3 w-100 border border-dark rounded-3 d-flex d-md-none">
                                <input type="text" className="form-control border-0" placeholder="Search Code" onChange={(e) => setSchoolCode(e.target.value)} />
                                <button className="btn btn-danger m-1 rounded-0 me-2" type="submit" onClick={() => navigate('/myinformation', { state: { schoolcode: SchoolCode } })}>Change
                                    School</button>
                            </div>
                        }
                        {/* <div className="border border-dark shadow-btm rounded-3 mt-md-5 mt-2 category"> */}
                        <div className="d-flex mt-md-5 mt-2 gap-2 flex-wrap">

                            {
                                cats.map((cat, i) => {
                                    const { cat_title, cat_id, subcats } = cat;
                                    return (
                                        <>
                                            <div className="dropdown shadow-btm rounded-2 border border-dark border-1">
                                                <button className="btn btn-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {cat_title}
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#" onClick={(e) => getSchoolSet(null, null)}>
                                                        <div className='border-bottom border-bottom-2 border-gray py-1'>
                                                            <span className='cursor-pointer'>All </span>
                                                        </div>
                                                    </a></li>
                                                    {subcats.map(subcat => {
                                                        const { class_title, class_id } = subcat
                                                        return (

                                                            <li><a className="dropdown-item" href="#" onClick={(e) => getSchoolSet(cat_id, class_id)}>
                                                                <div className='border-bottom border-bottom-2 border-gray py-1'>
                                                                    <span className='cursor-pointer' >Class <strong>{class_title}</strong></span>
                                                                </div>
                                                            </a></li>


                                                        )
                                                    })}

                                                </ul>
                                            </div>
                                        </>
                                    )
                                })
                            }

                        </div>



                        {/* </div> */}
                        <div className="row my-5 g-4">
                            {
                                schoolSetData.map((set, i) => {
                                    const {
                                        id, set_id, vendor_id, vendor, set_qty,
                                        setclass, cat_title, type_title,
                                        board, org_title, grade, set_price, total_discount,
                                        discounted_price, set_items, stock_status
                                    } = set;
                                    const itemsPerPage = 6;

                                    const totalPages = Math.ceil(set_items.length / itemsPerPage);

                                    const startIndex = currentPage * itemsPerPage;
                                    const currentItems = set_items.slice(startIndex, startIndex + itemsPerPage);
                                    let imageSrc;
                                    switch (cat_title) {
                                        case 'Stationery Set':
                                            imageSrc = require('../assets/images/stationary.jpg');
                                            break;
                                        case 'Sports Uniform':
                                            imageSrc = require('../assets/images/uniformset.jpeg');
                                            break;
                                        case 'Girl School Uniform':
                                            imageSrc = require('../assets/images/uniformset.jpeg');
                                            break;
                                        case 'Boy School Uniform':
                                            imageSrc = require('../assets/images/uniformset.jpeg');
                                            break;
                                        default:
                                            imageSrc = require('../assets/images/bookset.png');  // Fallback image in case of no match
                                            break;
                                    }
                                    return (
                                        <>
                                            <div className="col-md-6 ">
                                                <div className="card border schoolbookset border-dark shadow-btm rounded-4 h-100">
                                                    <div className="card-body d-flex text-md-start pb-md-2 pb-1 gap-2">
                                                        <div className="ms-sm-3 ms-1 text-start">

                                                            <h3 className="text-danger mb-0 cursor-pointer" data-bs-toggle="modal" data-bs-target={`#schoolsetmodal${i}`}>{setclass} Class {cat_title}</h3>
                                                            <h6 className='mb-0'>{type_title}</h6>
                                                            <h6 className='mb-0'>{set_qty} Quantity</h6>
                                                            <h5 className="fw-bold">Sold By: {vendor}</h5>
                                                            <h5 className='school-set-price'> {parseFloat(set_price) === parseFloat(discounted_price) ? '' : <del className="text-secondary">₹ {set_price}</del>}&nbsp;&nbsp;₹ {discounted_price}</h5>

                                                            <button type="button" className="d-md-inline mb-2  d-none btn rounded-3 border border-dark shadow-btm flex-column me-2" data-bs-toggle="modal" data-bs-target={`#schoolsetmodal${i}`}>Preview</button>


                                                            {
                                                                stock_status === 0 ?
                                                                    <button className="btn d-md-inline d-none mb-2   mt-2">Out of Stock</button>
                                                                    :
                                                                    <button type="button" onClick={() => addSetToCart(set_id, vendor_id, set_qty, true)} className="btn mb-2   d-md-inline d-none  rounded-3 border border-dark shadow-btm flex-column bg-danger text-white mt-lg-0 ">Buy
                                                                        Now</button>

                                                            }

                                                        </div>

                                                        <img src={imageSrc} alt='.' className="ms-auto img-fluid rounded-4 " width={150} />
                                                        <div className="modal fade py-5" id={`schoolsetmodal${i}`}>
                                                            <div className="modal-dialog  modal-xl">
                                                                <div className="modal-content">
                                                                    <div className="modal-header bg-danger text-white">
                                                                        <h4 className="modal-title ">Set Item</h4>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" />
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        {/* {JSON.stringify(set)} */}
                                                                        <div className="table-responsive p-3">
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">S.No</th>
                                                                                        <th scope="col">Image</th>
                                                                                        <th scope="col">Name</th>
                                                                                        <th scope="col">Class</th>
                                                                                        <th scope="col">Pricing</th>
                                                                                        <th scope="col">{cat_title === 'Text Books' ? 'Publisher' : 'Brand'} </th>
                                                                                        <th scope="col">Qty</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        currentItems.map((item, i) => (
                                                                                            <tr key={startIndex + i}>
                                                                                                <th scope="row">{startIndex + i + 1}</th>
                                                                                                {item.img !== 'inventory_set/' ? <td><img src={`${img_url}/${item.img}`} alt="book1" width={60} /></td> : <td><img src={imgPreview} alt="book1" width={60} /></td>}
                                                                                                {/* {item.img} */}
                                                                                                <td>{item.itemname}</td>
                                                                                                <td>{item.classno}</td>
                                                                                                <td>
                                                                                                    {parseFloat(item.price) === parseFloat(item?.new_price) ? '' : <del>₹{item?.price}</del>}&nbsp;&nbsp;₹{item?.new_price}
                                                                                                </td>
                                                                                                <td>{item.company_name}</td>
                                                                                                <td>{item.qty}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="row p-3">
                                                                            <div className="text-md-start col-md-6">
                                                                                <h6>Total Price : <span className="text-danger">₹{set_price}</span></h6>
                                                                                <h6>Discount Price : <span className="text-danger">₹{total_discount}</span></h6>
                                                                                <h6>Final Price : <span className="text-danger">₹{discounted_price}</span></h6>
                                                                            </div>
                                                                            <div className="col-md-6">

                                                                                <div className='justify-content-md-end justify-content-center d-flex'>

                                                                                    <h5>   <button
                                                                                        className='p-0 border-0 bg-transparent'
                                                                                        onClick={() => setCurrentPage(currentPage - 1)}
                                                                                        disabled={currentPage <= 0}
                                                                                    >
                                                                                        <i className={`ti ti-circle-arrow-left-filled ${currentPage <= 0 ? 'text-secondary' : 'text-dark'}`} />
                                                                                    </button>

                                                                                        <span className="mx-1">{currentPage + 1}</span>

                                                                                        <button
                                                                                            className='p-0 border-0 bg-transparent'
                                                                                            onClick={() => setCurrentPage(currentPage + 1)}
                                                                                            disabled={currentPage >= totalPages - 1}
                                                                                        >
                                                                                            <i className={`ti ti-circle-arrow-right-filled ${currentPage >= totalPages - 1 ? 'text-secondary' : 'text-dark'}`} />
                                                                                        </button></h5>
                                                                                </div>
                                                                                {/* <i className="ti ti-circle-arrow-right-filled" /> */}
                                                                                {
                                                                                    stock_status === 0 ?
                                                                                        <button data-bs-dismiss="modal" className="btn btn-pink"> Out of Stock</button>
                                                                                        :
                                                                                        <button type="button" onClick={() => addSetToCart(set_id, vendor_id, set_qty)} data-bs-dismiss="modal" className="mt-3 btn rounded-3 border border-dark shadow-btm float-md-end bg-danger text-white">Add
                                                                                            to Cart</button>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer border-0 my-0 py-1 d-md-none d-block">
                                                        <button type="button" className="btn rounded-3 border border-dark shadow-btm flex-column me-2" data-bs-toggle="modal" data-bs-target={`#schoolsetmodal${i}`}>Preview</button>
                                                        {
                                                            stock_status === 0 ?
                                                                <button className="btn btn-pink mt-2">Out of Stock</button>
                                                                :
                                                                <button type="button" onClick={() => addSetToCart(set_id, vendor_id, set_qty, true)} className="btn rounded-3 border border-dark shadow-btm flex-column bg-danger text-white mt-lg-0 mt-md-2">Buy
                                                                    Now</button>

                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </ >
                                    )
                                })}
                            <h4 className='text-danger'>
                                {schoolSetData.length < 1 && 'Set Not Found'}
                            </h4>
                        </div>
                    </div>
                </section>

                {/* <!-----------------------------------Banner Starts---------------------------------> */}
                {/* <section id="school_header" className='py-md-5'>
                    <div className='container-fluid p-0'>
                        <div className='d-md-none'>
                            {
                                schoolData.school_banner !== null ?
                                    <img src={`${img_url}/${schoolData.folder}/${schoolData.school_banner}`} alt="school banner" className="img-fluid d-block mx-auto" />
                                    :
                                  ''  // <img src={require('../assets/images/school_banner_2.jpeg')} alt="banner" className="img-fluid d-block mx-auto" />
                            }
                        </div>
                    </div>
                    <div className="container">
                        <div className='d-md-block d-none border border-4 rounded-end-3 border-danger'>
                            <div className="row g-0">
                                <div className="col-lg-7">
                                    {
                                        schoolData.school_banner !== null ?
                                            <img src={`${img_url}/${schoolData.folder}/${schoolData.school_banner}`} alt="school banner" className="img-fluid d-block mx-auto" />
                                            :
                                          ''  // <img src={require('../assets/images/school_banner_2.jpeg')} alt="banner" className="img-fluid d-block mx-auto" />
                                    }
                                </div>
                                <div className="col-lg-5">
                                    <div className='d-flex justify-content-center align-items-center bg-orange h-100'>
                                        <div className='my-4 text-center'>
                                            <p className='h4'>{schoolData.school_name}</p>
                                            <ul className="list-unstyled">
                                                <li>{schoolData.village && schoolData.village}{schoolData.landmark && `, ${schoolData.landmark}`}</li>
                                                <li>{schoolData.zipcode && `Pincode: ${schoolData.zipcode}`}{schoolData.post_office && `, Post Office: ${schoolData.post_office}`}</li>
                                                <li>{schoolData.city && schoolData.city}{schoolData.distt && `, ${schoolData.distt}`}{schoolData.state && `, ${schoolData.state}`}</li>
                                            </ul>
                                            <div className='d-flex justify-content-center'>
                                                <input type="text" className="form-control w-50 rounded-end-0" onChange={(e) => setSchoolCode(e.target.value)} name='SchoolCode' value={SchoolCode} id="SchoolCode" placeholder="School Code" />
                                                <button className='btn btn-pink rounded-start-0' onClick={() => navigate('/myinformation', { state: { schoolcode: SchoolCode } })}>Change School</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <!-----------------------------------Banner Ends-----------------------------------> */}

                {/* <section id="category-product myschool" className="pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className=''>
                                    <h3 className="bg-pink text-center text-white mb-0 p-1">Set Categories</h3>
                                    <div className="accordion border border-3 border-danger" id="accordionExample">

                                        {
                                            cats.map((cat, i) => {
                                                const { cat_title, cat_id, subcats } = cat;
                                                return (
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header p-2" id={`headingOne${i}`}>
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} aria-expanded="true" aria-controls={`collapse${i}`}>
                                                                <span className='h5 mb-0'>{cat_title}</span>
                                                            </button>
                                                        </h2>
                                                        <div id={`collapse${i}`} className="accordion-collapse collapse" aria-labelledby={`headingOne${i}`} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body pt-0">
                                                                {
                                                                    subcats.map(subcat => {
                                                                        const { class_title, class_id } = subcat;
                                                                        return (
                                                                            <>
                                                                                <div className='border-bottom border-bottom-2 border-gray py-1'>
                                                                                    <span data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} className='cursor-pointer' onClick={() => getSchoolSet(cat_id, class_id)}>Class <strong>{class_title}</strong></span>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-9 gap-5">
                                <div id="book-set">
                                    <div className="row">
                                        {
                                            schoolSetData.map((set, i) => {
                                                const {
                                                    id, set_id, vendor_id, vendor, set_qty,
                                                    setclass, cat_title, type_title,
                                                    board, org_title, grade, set_price, total_discount,
                                                    discounted_price, set_items, stock_status
                                                } = set;

                                                return (
                                                    <>
                                                        <div key={i} className="col-md-3 my-3">
                                                            <div id="flip-card">
                                                                <div className="book">
                                                                    <div className="">
                                                                        <table className="f-sm">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">S.No</th>
                                                                                    <th scope="col">Image</th>
                                                                                    <th scope="col">Name</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    set_items.slice(0, 4).map((item, i) => {
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td>{i + 1}</td>
                                                                                                <td><img src={`${img_url}/${item.img}`} alt="book1" className="book-img-size" /> </td>
                                                                                                <td>{item.itemname}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </tbody>
                                                                        </table>
                                                                        <div className='d-flex justify-content-center gap-2'>
                                                                            <button className="btn btn-outline-pink mt-2" data-bs-toggle="modal" data-bs-target={`#schoolsetmodal${i}`}>Preview</button>
                                                                            {
                                                                                stock_status === 0 ?
                                                                                <button className="btn btn-pink mt-2">Out of Stock</button>
                                                                                :
                                                                                <button onClick={() => addSetToCart(set_id, vendor_id, set_qty, true)} className="btn btn-pink mt-2">Buy Now</button>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="school_set_cover">
                                                                        <div className="card mx-auto">
                                                                            <div className="overflow-hidden schoolset_img_contain position-relative">
                                                                              <img src={require("../assets/images/my_school/set_cover_3.jpg")} className="mx-auto img-fluid rounded position-relative" alt="..." /> 
                                                                            </div>
                                                                            <div className="position-absolute text-light text-center h-100 w-100 p-4 bg-dark-shade">
                                                                                <div>
                                                                                    <div className='fw-bolder shadow'><span className='h4'>{setclass}</span> Class (Book Set)</div>
                                                                                    <div><del className='s-font2'>₹{set_price}</del><span className='fw-semibold'> ₹{discounted_price}</span></div>
                                                                                    <div>({type_title})</div>
                                                                                    <div>Sold By : {vendor}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*************************************modal*****************************

                                                            <div id="sets-table">
                                                                {
                                                                    <div key={i} className="modal fade modal-lg" id={`schoolsetmodal${i}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header bg-pink text-white">
                                                                                    <h5 className="modal-title">Set Items</h5>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    <table className="table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>S.No</th>
                                                                                                <th>Image</th>
                                                                                                <th>Name</th>
                                                                                                <th>Class</th>
                                                                                                <th>Price</th>
                                                                                                <th>Publisher</th>
                                                                                                <th>QTY</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                set_items.map((item, i) => {
                                                                                                    return (
                                                                                                        <tr >
                                                                                                            <td data-label="S.No">{i + 1}</td>
                                                                                                            <td data-label="Image"><img src={`${img_url}/${item.img}`} alt="book1" className="book-img-size" /></td>
                                                                                                            <td data-label="Name">{item.itemname}</td>
                                                                                                            <td data-label="Class">{item.classno}</td>
                                                                                                            <td data-label="Price"><del>₹{item.price}</del> ₹{item.new_price}</td>
                                                                                                            <td data-label="Publisher">{item.company_name}</td>
                                                                                                            <td data-label="QTY">{item.qty}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <div>
                                                                                        <h6>Total Price : <span className="text-danger">₹{set_price}</span></h6>
                                                                                        <h6>Discount Price : <span className="text-danger">₹{total_discount}</span></h6>
                                                                                        <h6>Final Price : <span className="text-danger">₹{discounted_price}</span></h6>
                                                                                        {
                                                                                            stock_status === 0 ?
                                                                                            <button data-bs-dismiss="modal" className="btn btn-pink"> Out of Stock</button>
                                                                                            :
                                                                                            <button onClick={() => addSetToCart(set_id, vendor_id, set_qty)} data-bs-dismiss="modal" className="btn btn-pink">Add To Cart</button>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </main >
            {/* <Footer /> */}
        </>
    )
}

export default MySchool